import { Layout } from '@components/layout';
import React, { useRef } from 'react';

import Arrow from './icons/arrowRight.svg';
import {
    container,
    container__items,
    container__items__single,
    container__items__single__link,
} from './styles/meaty.module.scss';

const SingleElement = ({ title, links }) => {
    return (
        <div className={container__items__single}>
            <h3>{title}</h3>
            {links.map((link, i) => {
                return (
                    <div className={container__items__single__link}>
                        <img src={Arrow} alt="arrow" />
                        <a href={link.url} target="_blank">
                            {link.label}
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

const Meaty = ({ pageContext }) => {
    const { page } = pageContext;
    return (
        <Layout {...page}>
            <div className={container}>
                <h1>Mięsne Wtorki? A co to takiego?</h1>
                <h2>
                    To cykl, w którym w każdy wtorek wrzucamy garść konkretnych
                    DARMOWYCH treści związanych z daną technologią czy
                    biblioteka, opracowania książek oraz inne materiały
                    edukacyjne.
                </h2>
                <div className={container__items}>
                    {ItemsTable.map((element, i) => {
                        return <SingleElement {...element} />;
                    })}
                </div>
            </div>
        </Layout>
    );
};

export default Meaty;

const ItemsTable = [
    {
        title: 'Memoizacja, czyli skuteczna technika do optymalizowania aplikacji',
        links: [
            {
                label: 'Czym w ogóle jest memoizacja i jak można użyć ją w JavaScriptcie?',
                url: 'https://www.section.io/engineering-education/an-introduction-to-memoization-in-javascript/',
            },
            {
                label: 'Memoization And Dynamic Programming Explained',
                url: 'https://youtu.be/WbwP4w6TpCk',
            },
            {
                label: 'Understanding JavaScript/TypeScript Memoization',
                url: 'https://betterprogramming.pub/understanding-javascript-typescript-memoization-6e0333b62406',
            },
            {
                label: 'Przykłady użycia memoizacji w React 1',
                url: 'https://epicreact.dev/memoization-and-react/',
            },
            {
                label: 'Przykłady użycia memoizacji w React 2',
                url: 'https://www.youtube.com/watch?v=o-alRbk_zP0',
            },
            {
                label: 'Przykłady użycia memoizacji w React 3',
                url: 'https://rossbulat.medium.com/how-to-memoize-in-react-3d20cbcd2b6e',
            },
            {
                label: 'Co za dużo to nie zdrowo - czyli kiedy nie opłaca się używać memoizacji',
                url: 'https://dmitripavlutin.com/use-react-memo-wisely/',
            },
        ],
    },
    {
        
        title: 'Domain Driven Desing - w skrócie DDD - o podejściu do programowania i projektowania architektury aplikacji',
        links: [
            {
                label: 'Wykłady twórcy - Erica Evansa 1',
                url: 'https://www.youtube.com/watch?v=pMuiVlnGqjk',
            },
            {
                label: 'Wykłady twórcy - Erica Evansa 2',
                url: 'https://youtu.be/am-HXycfalo',
            },
            {
                label: 'Wykład polskiego specjalisty od tego pojęcia - Sławomira Sobótki',
                url: 'https://www.youtube.com/watch?v=FkylT96at4g',
            },
            {
                label: 'Dodatkowe materiały do zagadnienia 1',
                url: 'https://medium.com/ssense-tech/domain-driven-design-everything-you-always-wanted-to-know-about-it-but-were-afraid-to-ask-a85e7b74497a',
            },
            {
                label: 'Dodatkowe materiały do zagadnienia 2',
                url: 'https://medium.com/microtica/the-concept-of-domain-driven-design-explained-3184c0fd7c3f',
            },
        ],
    },
    {
        title: 'Troszkę mięska z własnej stajni',
        links: [
            {
                label: 'Dla tych co mają trudności z myśleniem obiektowym i wyłanianiem abstrakcji ',
                url: 'https://academy.localhost-group.com/records/#abstrakcyjne-abstrakcje-i-programowanie-obiektowe',
            },
        ],
    },
    {
        title: 'Kanał na YouTube kogoś, kto dobrze gada!',
        links: [
            {
                label: 'To nie jest jedyny jego filmik, więc warto zajrzeć na kanał!',
                url: 'https://www.youtube.com/watch?v=wyABTfR9UTU',
            },
        ],
    },
    {
        title: 'Rozszerzenia do VSC',
        links: [
            {
                label: 'Top 10 VSC Extensions',
                url: 'https://blog.logrocket.com/top-10-vs-code-extensions-2021/',
            },
            {
                label: 'Rozszerzenia których koniecznie potrzebujesz',
                url: 'https://www.youtube.com/watch?v=qWNj3tfVjWI',
            },
            {
                label: 'Kolejnych 11 rozszerzeń na które warto spojrzeć',
                url: 'https://scotch.io/bar-talk/11-awesome-javascript-extensions-for-visual-studio-code?fbclid=IwAR0wcwdHjJm0XxBmtMTwZKWtV5P0rE0TEsHvHNf03C2vv6zZ2Binfes0gQI',
            },
            {
                label: 'Motyw który używam',
                url: 'https://draculatheme.com/visual-studio',
            },
            {
                label: 'Wtyczka dla osób które używają dockera',
                url: 'https://marketplace.visualstudio.com/items...',
            },
        ],
    },
    {
        title: 'Github Copilot',
        links: [
            {
                label: 'Co to jest Copilot?',
                url: 'https://copilot.github.com/',
            },
            {
                label: 'Jak działa?',
                url: 'https://www.youtube.com/watch?v=4duqI8WyfqE',
            },
            {
                label: 'Czy Copilot pozbawi Cię pracy?',
                url: 'https://www.youtube.com/watch?v=SAW-AV_MY2k',
            },
        ],
    },
    {
        title: "SQL'owe bazy danych - Prisma",
        links: [
            {
                label: 'Link główny',
                url: 'https://github.com/prisma/prisma ',
            },
            {
                label: 'Start TypeScript + Prisma',
                url: 'https://medium.com/codex/getting-started-with-the-best-typescript-orm-e0655dd3966?fbclid=IwAR1QvvlyZelqS7HYAmPdsctNo-r2QGNZDi_X6D_y_46uCxzKpXUr_GFqv-k',
            },
            {
                label: 'Czemu Prisma?',
                url: 'https://www.prisma.io/docs/concepts/overview/why-prisma?fbclid=IwAR2ZJpKggx_SVIbKn4EhGXz8WTFsx3rHIBpiCnO7kxGe73lwKnD9PLmcgzM',
            },
            {
                label: 'Architektura i podejście do projektowania I',
                url: 'HMZ4kOuIb_5eF1SfN28JENxkhz0z6ZYnFQ4hI4fgc',
            },
            {
                label: 'Architektura i podejście do projektowania II',
                url: 'https://www.toptal.com/express-js/nodejs-typescript-rest-api-pt-2?fbclid=IwAR0lo05mtKISL96dZWURmdYObZGfpUNNQxTJa1qU4k92l3psOduHXQaI7Lo',
            },
        ],
    },
    {
        title: 'Tailwind CSS',
        links: [
            {
                label: 'Świetna dokumentacja Tailwind CSS',
                url: 'https://tailwindcss.com/docs?fbclid=IwAR35kNDdtyr_d2CJG4YOtDCluFhhKpRWpfw4iaYN9XyNyItJn41SdmnRYTU',
            },
            {
                label: 'Plusy i minusy używania Tailwind CSS I',
                url: 'https://www.youtube.com/watch?v=hdGsFpZ0J2E&fbclid=IwAR22dv-UdM-auSvmLZMDHh9arj4xF0yHP16PK0IamY45cjD8jImNKkMUj_I',
            },
            {
                label: 'Plusy i minusy używania Tailwind CSS II',
                url: 'https://javascript.plainenglish.io/is-tailwind-css-really-worth-using-1830a706231a?fbclid=IwAR33pFNldSwA2c_6n5bzOB9bsI-UdVzMpjlNyrlDiENl3HEg_aVTpL6Neus',
            },
            {
                label: 'Plusy i minusy używania Tailwind CSS III',
                url: 'https://dev.to/joserfelix/why-use-tailwind-css-for-your-next-project-39hp?fbclid=IwAR1w3r0dVsXVCHEJBnI0jzsWlLc18AStVuJIbFsuVEdS0ekth2lusaDX7Bw',
            },
            {
                label: 'Jak używać Tailwind CSS I',
                url: 'https://www.youtube.com/watch?v=6zIuAyLZPH0',
            },
            {
                label: 'Jak używać Tailwind CSS II',
                url: 'https://www.youtube.com/watch?v=UBOj6rqRUME',
            },
            {
                label: 'Jak używać Tailwind CSS III',
                url: 'https://www.youtube.com/watch?v=gOQ31Kc8H5E',
            },
        ],
    },
    {
        title: 'Three.js - Biblioteka do grafiki 3D',
        links: [
            {
                label: 'Podstawy three.js w Vanilla JS I',
                url: 'https://www.youtube.com/watch?v=AYa0aJ9xd_U',
            },
            {
                label: 'Podstawy three.js w Vanilla JS II',
                url: 'https://www.youtube.com/watch?v=pUgWfqWZWmM',
            },
            {
                label: 'Podstawy three.js w Vanilla JS III',
                url: 'https://www.youtube.com/watch?v=Q7AOvWpIVHU',
            },
            {
                label: 'Użycie three.js w React wraz z biblioteką react-three-fiber I',
                url: 'https://www.youtube.com/watch?v=fdtqqyeKRJk',
            },
            {
                label: 'Użycie three.js w React wraz z biblioteką react-three-fiber II',
                url: 'https://www.youtube.com/watch?v=Qs9A3XIjbg4',
            },
            {
                label: 'Przykłady zaawansowanych projektów - turbulent',
                url: 'https://turbulent.ca/',
            },
            {
                label: 'Przykłady zaawansowanych projektów - bruno-simon',
                url: 'https://bruno-simon.com/',
            },
            {
                label: 'Przykłady zaawansowanych projektów - lusion',
                url: 'https://lusion.co/',
            },
        ],
    },
    {
        title: 'Testy dbające o wysoką jakosc kodu',
        links: [
            {
                label: 'AAA - O co chodzi?',
                url: 'https://medium.com/swlh/aaa-the-bread-and-butter-of-testing-b31d99c30aca',
            },
            {
                label: 'F.I.R.S.T - Krótko jak dobry test jednostkowy powinien się zachowywać',
                url: 'https://medium.com/@tasdikrahman/f-i-r-s-t-principles-of-testing-1a497acda8d6',
            },
            {
                label: 'Testy jednostkowe - Czemu warto?',
                url: 'https://fortegrp.com/the-importance-of-unit-testing/',
            },
            {
                label: "'Testy jednostkowe. Zasady, praktyki i wzorce' - Vladimir Khorikov",
                url: 'https://www.youtube.com/watch?v=5iJWOPaNZDA',
            },
        ],
    },
    {
        title: 'Redux',
        links: [
            {
                label: 'Czym w ogóle jest Redux I',
                url: 'https://egghead.io/courses/fundamentals-of-redux-course-from-dan-abramov-bd5cc867?fbclid=IwAR2lFVWjWtNw8_1ySPY3dJlddaifaY8zON2Srkatqm5MXclJUEcCdBhZRqw',
            },
            {
                label: 'Czym w ogóle jest Redux II',
                url: 'https://www.youtube.com/watch?v=CVpUuw9XSjY',
            },
            {
                label: 'Czym w ogóle jest Redux III',
                url: 'https://www.youtube.com/watch?v=fOqq92-5L5M',
            },
            {
                label: 'Redux vs React Context',
                url: 'https://www.fireup.pro/blog/redux-vs-react-context-which-should-you-use?fbclid=IwAR2CAW3pTdzvckjhd8DdQBdBSkUJFdtUkuNtpl1mIxkG3YRoeDErKUW3rNQ',
            },
            {
                label: 'Filmik z Gladiatorów',
                url: 'https://drive.google.com/file/d/1lBFB1LgAIklAvjhsZ0NPl8ScVR8loQST/view?fbclid=IwAR3A1lvF-qQLg2BIrKOPNDL9Y66e-1CzKP07eDCmc-n5vN8A-rHmD3v_ZDw',
            },
        ],
    },
    {
        title: 'Big O Notation',
        links: [
            {
                label: 'Wiedza na temat Big-O Notation I',
                url: 'https://www.khanacademy.org/computing/computer-science/algorithms/asymptotic-notation/a/big-o-notation?fbclid=IwAR1yVkZXw4x9F9oFsCc8j6P6Wc9JNQl52BOXyZTFMtEZAecVQ2rIG4RM5i4',
            },
            {
                label: 'Wiedza na temat Big-O Notation II',
                url: 'https://www.freecodecamp.org/news/big-o-notation-why-it-matters-and-why-it-doesnt-1674cfa8a23c/?fbclid=IwAR3D6UpegLxLtWCXE6BYxlMAqheJuQ6VP3hBwdEbmob4ihdZwmHz2it3pFU',
            },
            {
                label: 'Wiedza na temat Big-O Notation III',
                url: 'https://dev.to/yashwanthambati/big-o-notation-complete-guide-226h?fbclid=IwAR2Y3hVSrPKvUw8L7kJrDZBkXyyIJB97ZIN2FxrGp4QWZLJ-923TuiIad9Y',
            },
            {
                label: 'Ściągawka',
                url: 'https://www.bigocheatsheet.com/?fbclid=IwAR1yVkZXw4x9F9oFsCc8j6P6Wc9JNQl52BOXyZTFMtEZAecVQ2rIG4RM5i4',
            },
        ],
    },
    {
        title: 'NestJS',
        links: [
            {
                label: 'Dlaczego powinieneś wybrać NestJS zamiast innych frameworków cz.1',
                url: 'https://javascript.plainenglish.io/be-cool-get-started-with-nestjs-a-modern-web-framework-for-node-js-31b25e6348d5',
            },
            {
                label: 'Dlaczego powinieneś wybrać NestJS zamiast innych frameworków cz.2',
                url: 'https://medium.com/monstar-lab-bangladesh-engineering/why-i-choose-nestjs-over-other-node-js-frameworks-6cdbd083ae67',
            },
            {
                label: 'Szybki start z NextJS po Polsku',
                url: 'https://www.youtube.com/watch?v=f1AZPADvj5s&list=PLjHmWifVUNMIy6w0l23VRunwZvMGMrRm9',
            },
            {
                label: 'Szybki start z NextJS po Angielsku',
                url: 'https://www.youtube.com/watch?v=wqhNoDE6pb4&list=PLzONrNTuf9lrlbN5vwFSpfCyqf9aBvgRK',
            },
            {
                label: 'Kamil Myśliwiec (twórca NestJS) - Revealing framework fundamentals: NestJS behind the curtain',
                url: 'https://www.youtube.com/watch?v=jo-1EUxMmxc',
            },
            {
                label: 'Kamil Myśliwiec (twórca NestJS) - Wykorzystaj potencjał Node.js z frameworkiem Nest - Gorrion Unplugged #1',
                url: 'https://www.youtube.com/watch?v=6WGquNHd9wc',
            },
        ],
    },
    {
        title: 'Next.js',
        links: [
            {
                label: 'Zasoby do nauki, gruntownie i od podstaw',
                url: 'https://www.freecodecamp.org/news/the-next-js-handbook/',
            },
            {
                label: 'Next.js Tutorial - Bruno Antunes',
                url: 'https://www.youtube.com/watch?v=7J4iL1HDshQ&list=PLYSZyzpwBEWSQsrukurP09ksi49H9Yj40&ab_channel=BrunoAntunes',
            },
            {
                label: 'Learn Next.js in One Video - Fundamentals of Next.js',
                url: 'https://www.youtube.com/watch?v=tt3PUvhOVzo&ab_channel=codedamn',
            },
            {
                label: 'Dobre repo z badziej sprecyzowanymi materiałami i boilerplatami',
                url: 'https://github.com/unicodeveloper/awesome-nextjs',
            },
        ],
    },
    {
        title: 'SOLID',
        links: [
            {
                label: 'Postawy cz.1',
                url: 'https://zniszcz.github.io/solid-js/',
            },
            {
                label: 'Podstawy cz.2',
                url: 'https://thefullstack.xyz/solid-javascript',
            },
            {
                label: 'Podstawy cz.3',
                url: 'https://www.hanselman.com/blog/hanselminutes-podcast-145-solid-principles-with-uncle-bob-robert-c-martin?fbclid=IwAR2KhivJ9dH8M-JHyGt4nfsIVCrbuKTmK0TYZq-4PS8pcPBDLNT3h6TPmXg',
            },
            {
                label: 'Video tutorial nr.1',
                url: 'https://www.youtube.com/watch?v=HYG-AxAOYoM&ab_channel=koduje',
            },
            {
                label: 'Video tutorial nr.2',
                url: 'https://www.youtube.com/watch?v=o1-AOrnRxzk&ab_channel=ProgressTelerik',
            },
            {
                label: 'Video tutorial nr.3',
                url: 'https://www.youtube.com/watch?v=zHiWqnTWsn4&ab_channel=FucktheCommunism',
            },
            {
                label: 'Video tutorial nr.4',
                url: 'https://www.youtube.com/watch?v=XzdhzyAukMM&ab_channel=freeCodeCamp.org',
            },
        ],
    },
    {
        title: 'Clean Code w wydaniu TS i JS',
        links: [
            {
                label: 'Na początek 9h video',
                url: 'https://gladiators-of-javascript.com/blog/9h-wykladow-clean-code-uncle-bob/?fbclid=IwAR1x4TgI4lTAdiHKGArEtXEny3VOxuyOEoIGHzjO69xojePHZouQ34MtwjA',
            },
            {
                label: '2 repozytoria - jak pisać lepszy kod',
                url: 'https://gladiators-of-javascript.com/blog/jak-pisac-lepszy-kod-clean-code-w-js-i-w-ts/?fbclid=IwAR2Ts8EeshCP5-HDN20xlddd_DU_jmlQ-XMS5SHCKrzDYKdoHJIUzK7H3qA',
            },
            {
                label: 'Ćwiczenia ze zbioru zadań JS, Node, React',
                url: 'https://github.com/Przemocny/zbior-zadan-html-css-js-react',
            },
        ],
    },
    {
        title: 'AWSowa uczta dla backendowców i frontendowców',
        links: [
            {
                label: 'Jak postawić apkę Expressa/Nexta/Reacta na instancji AWS EC2 i podpiąć ją pod customową domenę z Route 53 i zrobić sobie na niej certyfikat SSL, aby mieć https',
                url: 'https://www.youtube.com/watch?v=dMVy3BQB314&ab_channel=HuakunShen',
            },
        ],
    },
    {
        title: 'React context toolkit',
        links: [
            {
                label: 'Proste rozwiązanie, które zakodowałem (wraz z jednym z Gladiatorów - Refixem) służące do wygodniejszego zarządzania stanem i akcjami w obrębie Context Api Reacta',
                url: 'https://github.com/Przemocny/react-context-toolkit',
            },
        ],
    },
    {
        title: 'React',
        links: [
            {
                label: 'Artykuł o stacku Reacto-pochodnym',
                url: 'https://javascript.plainenglish.io/react-learning-roadmap-for-2021-a1c0f7456186',
            },
            {
                label: 'Tao Reacta',
                url: 'https://alexkondov.com/tao-of-react/',
            },
        ],
    },
    {
        title: 'Separation of Concerns',
        links: [
            {
                label: 'Link do nagrania z Gladiatorów z wykładem',
                url: 'https://drive.google.com/file/d/1hCV64Q3D-_paqo4WDt2nGEKP18GeN-JQ/view?fbclid=IwAR1TMuU0q5UXmhyxg4tD5WyPznVfd-fAvrNaVJWwLrHGzVD8ggK1XFPVhOg',
            },
        ],
    },
    {
        title: 'Dobre praktyki oraz Clean Code w JS i TS',
        links: [
            {
                label: 'Clean Code w JS i w TS',
                url: 'https://gladiators-of-javascript.com/blog/jak-pisac-lepszy-kod-clean-code-w-js-i-w-ts/?fbclid=IwAR1EKG2AjyoPYon_24oyk5LRnUw_6dTDSsxQgWxxLfvzFZ6I2g8516vFu9M',
            },
            {
                label: 'Arraye i Obiekty cz.1',
                url: 'https://javascript.plainenglish.io/12-methods-for-finding-an-item-in-an-array-and-array-of-objects-in-javascript-484a1ba66324',
            },
            {
                label: 'Arraye i Obiekty cz.2',
                url: 'https://javascript.plainenglish.io/21-arrays-and-object-tricks-in-javascript-typescript-9d41f5f4966c',
            },
            {
                label: 'Dobre praktyki i triki optymalizacyjne cz.1',
                url: 'https://javascript.plainenglish.io/42-tips-and-tricks-to-write-faster-better-optimized-javascript-code-3a82c53d051e',
            },
            {
                label: 'Dobre praktyki i triki optymalizacyjne cz.2',
                url: 'https://javascript.plainenglish.io/34-javascript-optimization-techniques-to-know-in-2021-d561afdf73c3',
            },
        ],
    },
    {
        title: 'Jak pisać lepszy kod w apkach Reacta',
        links: [
            {
                label: 'DRY in React apps',
                url: 'https://javascript.plainenglish.io/5-dry-principles-to-follow-in-react-6ca0405986a9',
            },
            {
                label: 'SOLID in React apps cz.1',
                url: 'https://medium.com/@jaymykels69/s-o-l-i-d-principles-with-react-cd43fc93b1be',
            },
            {
                label: 'SOLID in React apps cz.2',
                url: 'https://blog.usejournal.com/how-to-apply-solid-principles-in-react-applications-6c964091a982',
            },
        ],
    },
    {
        title: 'Skarby prosto z Akademii',
        links: [
            {
                label: 'Przykładowe zadania z roadmapy',
                url: 'https://github.com/Przemocny/zbior-zadan-html-css-js-react',
            },
            {
                label: 'Link do grafiki i skrótu zawartośc',
                url: 'http://lha.api.localhost-group.com/wp-content/uploads/2021/03/flowchartjsts.pdf',
            },
            {
                label: 'Przeczytaj o więcej o roadmapach',
                url: 'O Roadmapach można poczytać na',
            },
        ],
    },
    {
        title: 'Materiały na różnych poziomach trudności',
        links: [
            {
                label: 'Różne wzorce architektoniczne',
                url: 'https://levelup.gitconnected.com/software-architecture-the-important-architectural-patterns-you-need-to-know-a1f5ea7e4e3d',
            },
            {
                label: 'Ile trzeba znać technologii, aby być web devem w 2021',
                url: 'https://javascript.plainenglish.io/ultimate-web-developer-roadmap-for-2021-6758edd0bd3f',
            },
            {
                label: 'Ile trzeba znać technologii, aby być react js devem w 2021',
                url: 'https://javascript.plainenglish.io/react-learning-roadmap-for-2021-a1c0f7456186',
            },
            {
                label: 'Ile trzeba znać technologii, aby być node devem js w 2021',
                url: 'https://javascript.plainenglish.io/node-js-developer-roadmap-for-2021-2ae9c057bf',
            },
            {
                label: 'Sprawdzone materiały oraz rozwiązania do zadań',
                url: 'https://academy.localhost-group.com/roadmaps',
            },
        ],
    },
    {
        title: 'Coś płatnego i za free',
        links: [
            {
                label: 'FREE - Libka react-virtualized',
                url: 'https://betterprogramming.pub/how-to-improve-rendering-performance-in-a-1-000-item-react-list-85be129b8c0d',
            },
            {
                label: 'FREE - Zbiór zadań Przemocnego',
                url: 'https://github.com/Przemocny/zbior-zadan-html-css-js-react/blob/master/Zbi%C3%B3r%20React/Components/InfiniteHumanList.md',
            },
            {
                label: 'PAY - Medium.com',
                url: 'https://medium.com/',
            },
            {
                label: 'PAY - E-book na temat wzorców projektówych z przykładami w TS i innych językach',
                url: 'https://refactoring.guru/design-patterns/book',
            },
        ],
    },
    {
        title: 'O podstawach OOP',
        links: [
            {
                label: 'Jak rozumieć abstrakcję, co to jest interfejs i czym różni się od klasy oraz o podstawowych założeniach oop - hermetyzacja, dziedziczenie i polimorfizm',
                url: 'https://gladiators-of-javascript.com/records',
            },
        ],
    },
    {
        title: 'Kolejna spora porcja wiedzy - prosto z portalu dev.to',
        links: [
            {
                label: 'Lista Githubów z wiedzą, api, książkami',
                url: 'https://dev.to/pb/10-github-repositories-every-developer-should-know-ngm',
            },
            {
                label: '200 różnych zasobów z JSa, aby poprawić swoje skille',
                url: 'https://dev.to/worldindev/200-js-resources-to-master-programming-3aj6',
            },
        ],
    },
    
    
    

];
